import { graphql } from 'gatsby';
import Policy from 'src/components/policy';

export default Policy;

export const pageQuery = graphql`
  {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/policies/store/" } }, sort: { frontmatter: { date: DESC } }) {
      edges {
        node {
          frontmatter {
            date
            title
          }
          html
        }
      }
    }
  }
`;
